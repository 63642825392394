<template>
  <div class="service" id="Service">
    <Header/>
    <div class="other__narrow__banner" id="ServiceCenter">
      <img  :src="'/upload/'+item.service.image" alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4 v-html="item.service.title"></h4>
        </div>
      </div>
    </div>

    <div class="service_section_one_wrapper" id="ServiceOurService">
      <div class="container">
        <div class="other__ck__title title__bar">
          <h2 v-html="item.serviceOurService.title"></h2>
        </div>
        <div class="other__ck__box" v-html="item.serviceOurService.keyword" style="display:block;">
        </div>
      </div>
    </div>

    <div class="service_section_wrapper" v-html="item.serviceOurService.content">
    </div>

    <div class="other__banner">
      <img  :src="'/upload/'+item.serviceLocation.image"  alt="" />
      <div class="service_banner_first_title">
        <h2>{{item.serviceLocation.banner}}</h2>
      </div>
    </div>
    
    
    <div class="about__history" id="ServiceLocation">
      <div class="container">
        <div class="about__history__title service_title">
          <h2>{{this.item.serviceLocation.title}}</h2>
        </div>
        <!-- mobile -->
        <div class="about__history__section">
          <button v-for="a in item.serviceLocationName" :key="a.id"
            type="button"
            class="btn btn-pic"
            :data-toggle="modal"
            :data-target="'#modaltarget'+a.id">
            <div class="about__history__block service__place">
              <div class="movile_pic_nav">
                <div class="moblie_pic_tilte">
                  {{a.title}}
                </div>
                <div class="mobilbe_pic_more">
                  查看更多
                </div>
              </div>
              <div class="about__history__img">
                <img  :src="'https://www.elevator.com.vn/upload/'+a.image"  alt=""/>
              </div>
              <div class="other__ck__title title__bar"
                  :class="{title__disable: !showActiveTaiwan}"
              >
                <h2 v-html="a.title"></h2>
              </div>
            </div>
          </button>
        </div>
        <!-- modal 服務據點-->
        <div
          v-for="a in item.serviceLocationName" :key="a.id"
          class="modal fade"
          :id="'modaltarget'+a.id"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{a.title}}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="show__service__place__block" v-for="element in item.serviceLocationStore.filter(item => item.parent_id === a.id)" :key="element.id">
                  <div class="show__service__place__title">
                    {{element.title}}
                  </div>
                  <div class="show__service__place__text">
                    <p>{{element.addr}}</p>
                    <p>{{element.tel}}</p>
                    <p>{{element.fax}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- pc -->
        <div class="pc_service_section owl-carousel">
          <button
            v-for="a in item.serviceLocationName" :key="a.id"
            type="button"
            class="btn item btn-pic"
            :data-toggle="modal"
            data-target="#Place_Taiwan"
            @click="setServiceLocation(a.id),clickButtonActive($event)"
          >
            <div class="about__history__block service__place">
              <div class="about__history__img">
                <img :src="'https://www.elevator.com.vn/upload/'+a.image"  alt=""/>
              </div>
              <div class="other__ck__title title__bar title__disable">
                <h2>{{a.title}}</h2>
              </div>
            </div>
          </button>
        </div>

        <!-- 桌機版 台灣區服務據點 -->
        <transition-group class="show__service__place" name="fade" >
          <div class="show__service__place__block" v-for="element in this.serviceLocation" :key="element.id">
            <div class="show__service__place__title">
              {{element.title}}
            </div>
            <div class="show__service__place__text">
              <p>{{element.addr}}</p>
              <p>{{element.tel}}</p>
              <p>{{element.fax}}</p>
            </div>
          </div>
        </transition-group>
        
      </div>
    </div>


    
    
    <div class="service_section_wrapper" id="ServiceRemoteControl">
      <div class="container">
        <div class="other__ck__title">
          <h2  v-html="item.serviceRemoteControl.title"></h2>
        </div>
        <div class="service_txt_wrapper">
          <p v-html="item.serviceRemoteControl.descript">
              </p>
        </div>
        <div class="other__ck__img service__block">
          <div class="service_pic_wrapper">
            <img  :src="'https://www.elevator.com.vn/upload/'+item.serviceRemoteControl.image"  alt="" />
          </div>
          <div class="service_pic_wrapper">
            <img  :src="'https://www.elevator.com.vn/upload/'+item.serviceRemoteControl.image1"  alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="other__banner">
      <img :src="'https://www.elevator.com.vn/upload/'+item.serviceExample.image"   alt="" />
      <div class="other__banner__title">
        <h2  v-html="item.serviceExample.banner"></h2>
      </div>
    </div>

    <div class="service_section_two_wrapper" id="ServiceExample">
      <div class="container"  v-html="item.serviceExample.content">
      </div>
    </div>

    <div class="other__banner">
      <img :src="'https://www.elevator.com.vn/upload/'+ item.serviceProductReponsibility.image"  alt="" />
      <div class="service_banner_first_title">
        <h2 style="text-shadow:1px 1px black;">{{item.serviceProductReponsibility.banner}}</h2>
      </div>
    </div>

    <div class="service_section_two_wrapper" id="ServiceProductReponsibility">
      <div class="container">
        <div class="other__ck__title">
          <h2>{{item.serviceProductReponsibility.title}}</h2>
        </div>
        <div class="other__ck__text" v-html="item.serviceProductReponsibility.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Header from './../components/Header'
import carousel from "vue-owl-carousel";
import './../assets/js/owl.carousel'
import './../assets/js/main.js'
import { apiService } from "@/api/pages.js";

export default {
  name: "Service",
  components: {
    Header,
    carousel
  },
  data(){
    return {
      hash: '',
      modal: '',
      serviceLocation:[],
      owlCarousel: true,
      showActiveTaiwan: true, 
      showActiveChina: false,
      showActiveVietnam: false,
      showActiveIndia: false,
      item:{}
    }
  },
  created(){
    this.getServiceData()
    this.getHashValue()
  },
  mounted: function () {
    this.createdSetWidth()
    document.querySelector('body').scrollIntoView({behavior: "smooth"})
    setTimeout(() => {
      $(".owl-carousel").owlCarousel({
        loop: false,
        margin: 0,
        autoHeight: true,
        autoplay: false,
        smartSpeed: 1000,
        nav: true,
      });
    }, 800);
    setTimeout(() => {
      $('.pc_service_section .title__bar:first').toggleClass('title__disable')
    }, 900);
    setTimeout( () => {
      const target = $(`${this.hash}`).offset().top
      $(window).scrollTop(target)
    }, 500)
  },
  methods:{
    async getServiceData(){
      const res = await apiService();
      this.item = res.data;
      console.log(this.item )
      console.log("item.serviceLocationName",this.item.serviceLocationName)
      this.item.serviceLocationStore.forEach(element => {
        var tmp = JSON.parse(element.json);
        // console.log('tmp:', tmp)
        for (var i in tmp)
        if (!element[i])
          element[i] = tmp[i];
          // console.log(element[i])
      });
      this.setServiceLocationInit()
    },
    setServiceLocationInit(){
      const firstDataId = this.item.serviceLocationName[0].id
      const firstDataStore = this.item.serviceLocationStore.filter(item => item.parent_id === firstDataId)
      this.serviceLocation = firstDataStore
    },
    setServiceLocation(id){
      const showDataStore = this.item.serviceLocationStore.filter(item => item.parent_id === id)
      this.serviceLocation = showDataStore
    },
    clickButtonActive(event){
      $('.pc_service_section .title__bar').attr('class', 'other__ck__title title__bar title__disable')
      $(event.currentTarget.children).children('.title__bar').toggleClass('title__disable')
    },
    createdSetWidth(){
      let winWidth = $(window).width()
      if(winWidth >=768){
        this.modal = 'nomodal'
      }else{
        this.modal = 'modal'
      } 
      $(window).on("resize", () => {
        winWidth = $(window).width()
        if(winWidth >=768){
          console.log('nomodal')
          this.modal = 'nomodal'
        }else{
          console.log('modal')
          this.modal = 'modal'
      } 
      })
    },
    getHashValue(){
      if(this.$route.hash){
        this.hash = this.$route.hash
      }else{
        this.hash = `#${this.$route.name}`
      }
    },
  }

};
</script>
<style lang="css" scope="">
  .fade-enter,
  .fade-leave-to{
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-acitve{
    transition: opacity .5s ;
  }
  .service__list__content__title p {
    font-weight: 900;
  }
</style>